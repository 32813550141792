import { Pipe, PipeTransform } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";

@Pipe({
  name: 'formControlToFormGroup',
  standalone: true
})
export class FormControlToFormGroupPipe implements PipeTransform {
  transform(value: AbstractControl): FormGroup {
    return value as FormGroup
  }
}
