import { HttpClient, HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { InvocationResult } from 'projects/hcl-lib/src/lib/interfaces/invocation-result'
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { HclConfigService } from '../../config/hcl.config.service'
import { AppPluginConfig } from '../../interfaces/app-plugin-config'
import { AppPluginConfigDto } from "../../interfaces/dto/app-plugin-config-dto"
import { PaginatedData } from '../../interfaces/paginated-data'
import { HttpShowError } from '../../decorator/http/http-show-error.decorator'
import { InvocationResultUtil } from '../../util/invocation-result.util'

@Injectable({
  providedIn: 'root'
})
export class PluginConfigService {

  constructor(
    private httpClient: HttpClient,
    private configService: HclConfigService
  ) { }

  @HttpShowError()
  getPluginConfig(id: string): Observable<AppPluginConfig> {
    return this.httpClient.get(this.configService.cmnAppApiBaseUrl + '/plugin-configs/' + id).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  getPluginConfigByName(name: string): Observable<AppPluginConfig> {
    return this.httpClient.get(this.configService.cmnAppApiBaseUrl + '/plugin-configs/by-name/' + name).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  getPluginConfigs(page: Number, perPage: Number, pluginId?: string
  ): Observable<PaginatedData<AppPluginConfig>> {
    const url = `${this.configService.cmnAppApiBaseUrl}/plugin-configs`
    let queryParams = new HttpParams()
      .set('perPage', perPage.toString())
      .set('page', page.toString())
    if (pluginId) {
      queryParams = queryParams.set('pluginId', pluginId)
    }
    return this.httpClient.get(url, { params: queryParams }).pipe(
      map((res: any) => {
        return InvocationResultUtil.mapToPaginatedData(res as InvocationResult) as PaginatedData<AppPluginConfig>
      })
    )
  }

  createPluginConfig(pluginConfig: AppPluginConfig): Observable<AppPluginConfig> {
    const url = `${this.configService.cmnAppApiBaseUrl}/plugin-configs`
    const pluginConfigDto: AppPluginConfigDto = {
      pluginId: pluginConfig.pluginId,
      config: pluginConfig.config
    }
    return this.httpClient.post(url, pluginConfigDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  updatePluginConfig(pluginConfig: AppPluginConfig): Observable<AppPluginConfig> {
    const url = `${this.configService.cmnAppApiBaseUrl}/plugin-configs/${pluginConfig.id}`
    const pluginConfigDto: AppPluginConfigDto = pluginConfig
    return this.httpClient.put(url, pluginConfigDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  deletePluginConfig(id: string) {
    const url = `${this.configService.cmnAppApiBaseUrl}/plugin-configs/${id}`
    return this.httpClient.delete(url)
  }
}
